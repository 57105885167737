import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/beadswapfrontend/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Info = makeShortcode("Info");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Info mdxType="Info">
BeadswapV1Router01 should not be used any longer, because of the discovery of a <Link to='/docs/v1/smart-contracts/router01#getamountin' mdxType="Link">low severity bug</Link> and the fact that some methods do not work with tokens that take fees on transfer. The current recommendation is to use <Link to='/docs/v1/smart-contracts/router02' mdxType="Link">BeadswapV1Router02</Link>.
    </Info>
    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/Beadswap/Beadswap-V1-Periphery/blob/master/contracts/BeadswapV1Router01.sol"
      }}><inlineCode parentName="a">{`BeadswapV1Router01.sol`}</inlineCode></a></p>
    <h1 {...{
      "id": "address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#address",
        "aria-label": "address permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Address`}</h1>
    <p><inlineCode parentName="p">{`BeadswapV1Router01`}</inlineCode>{` is deployed at `}<inlineCode parentName="p">{`0x404A895237C97B75d4Cf96C8Ad9760687692613A`}</inlineCode>{` on the Binance Smart Chain `}<a parentName="p" {...{
        "href": "https://bscscan.com/address/0x404A895237C97B75d4Cf96C8Ad9760687692613A"
      }}>{`mainnet`}</a>{`, and the `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0x404A895237C97B75d4Cf96C8Ad9760687692613A"
      }}>{`Testnet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0x404A895237C97B75d4Cf96C8Ad9760687692613A"
      }}>{`Testnet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://goerli.bscscan.com/address/0x404A895237C97B75d4Cf96C8Ad9760687692613A"
      }}>{`Görli`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0x404A895237C97B75d4Cf96C8Ad9760687692613A"
      }}>{`Testnet`}</a>{` testnets. It was built from commit `}<a parentName="p" {...{
        "href": "https://github.com/Beadswap/Beadswap-V1-Periphery/tree/2ad7da28a6f70ec4299364bc1608af8f30e7646b"
      }}>{`2ad7da2`}</a>{`.`}</p>
    <h1 {...{
      "id": "read-only-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#read-only-functions",
        "aria-label": "read only functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Read-Only Functions`}</h1>
    <h2 {...{
      "id": "factory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#factory",
        "aria-label": "factory permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`factory`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function factory() external pure returns (address);
`}</code></pre>
    <p>{`Returns `}<Link to='/docs/v1/smart-contracts/factory/#address' mdxType="Link">{`factory address`}</Link>{`.`}</p>
    <h2 {...{
      "id": "wbnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wbnb",
        "aria-label": "wbnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WBNB`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function WBNB() external pure returns (address);
`}</code></pre>
    <p>{`Returns the `}<a parentName="p" {...{
        "href": "https://blog.0xproject.com/canonical-weth-a9aa7d0279dd"
      }}>{`canonical WBNB address`}</a>{` on the Binance Smart Chain `}<a parentName="p" {...{
        "href": "https://bscscan.com/address/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
      }}>{`mainnet`}</a>{`, or the `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0xc778417e063141139fce010982780140aa0cd5ab"
      }}>{`Testnet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0xc778417e063141139fce010982780140aa0cd5ab"
      }}>{`Testnet`}</a>{`, `}<a parentName="p" {...{
        "href": "https://goerli.bscscan.com/address/0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6"
      }}>{`Görli`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://testnet.bscscan.com/address/0xd0a1e359811322d97991e03f863a0c30c2cf029c"
      }}>{`Testnet`}</a>{` testnets.`}</p>
    <h1 {...{
      "id": "state-changing-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#state-changing-functions",
        "aria-label": "state changing functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`State-Changing Functions`}</h1>
    <h2 {...{
      "id": "addliquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#addliquidity",
        "aria-label": "addliquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`addLiquidity`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function addLiquidity(
  address tokenA,
  address tokenB,
  uint amountADesired,
  uint amountBDesired,
  uint amountAMin,
  uint amountBMin,
  address to,
  uint deadline
) external returns (uint amountA, uint amountB, uint liquidity);
`}</code></pre>
    <p>{`Adds liquidity to an BEP-20⇄BEP-20 pool.`}</p>
    <ul>
      <li parentName="ul">{`To cover all possible scenarios, `}<inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least amountADesired/amountBDesired on tokenA/tokenB.`}</li>
      <li parentName="ul">{`Always adds assets at the ideal ratio, according to the price when the transaction is executed.`}</li>
      <li parentName="ul">{`If a pool for the passed tokens does not exists, one is created automatically, and exactly amountADesired/amountBDesired tokens are added.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountADesired`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenA to add as liquidity if the B/A price is <= amountBDesired/amountADesired (A depreciates).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBDesired`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenB to add as liquidity if the A/B price is <= amountADesired/amountBDesired (B depreciates).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountAMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bounds the extent to which the B/A price can go up before the transaction reverts. Must be <= amountADesired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bounds the extent to which the A/B price can go up before the transaction reverts. Must be <= amountBDesired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the liquidity tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenA sent to the pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenB sent to the pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens minted.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "addliquiditybnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#addliquiditybnb",
        "aria-label": "addliquiditybnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`addLiquidityBNB`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function addLiquidityBNB(
  address token,
  uint amountTokenDesired,
  uint amountTokenMin,
  uint amountBNBMin,
  address to,
  uint deadline
) external payable returns (uint amountToken, uint amountBNB, uint liquidity);
`}</code></pre>
    <p>{`Adds liquidity to an BEP-20⇄WBNB pool with BNB.`}</p>
    <ul>
      <li parentName="ul">{`To cover all possible scenarios, `}<inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least amountTokenDesired on token.`}</li>
      <li parentName="ul">{`Always adds assets at the ideal ratio, according to the price when the transaction is executed.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`msg.value`}</inlineCode>{` is treated as a amountBNBDesired.`}</li>
      <li parentName="ul">{`Leftover BNB, if any, is returned to `}<inlineCode parentName="li">{`msg.sender`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`If a pool for the passed token and WBNB does not exists, one is created automatically, and exactly amountTokenDesired/`}<inlineCode parentName="li">{`msg.value`}</inlineCode>{` tokens are added.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountTokenDesired`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of token to add as liquidity if the WBNB/token price is <= `}<inlineCode parentName="td">{`msg.value`}</inlineCode>{`/amountTokenDesired (token depreciates).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`msg.value`}</inlineCode>{` (amountBNBDesired)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB to add as liquidity if the token/WBNB price is <= amountTokenDesired/`}<inlineCode parentName="td">{`msg.value`}</inlineCode>{` (WBNB depreciates).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountTokenMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bounds the extent to which the WBNB/token price can go up before the transaction reverts. Must be <= amountTokenDesired.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bounds the extent to which the token/WBNB price can go up before the transaction reverts. Must be <= `}<inlineCode parentName="td">{`msg.value`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the liquidity tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountToken`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of token sent to the pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB converted to WBNB and sent to the pool.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens minted.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "removeliquidity",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquidity",
        "aria-label": "removeliquidity permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidity`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidity(
  address tokenA,
  address tokenB,
  uint liquidity,
  uint amountAMin,
  uint amountBMin,
  address to,
  uint deadline
) external returns (uint amountA, uint amountB);
`}</code></pre>
    <p>{`Removes liquidity from an BEP-20⇄BEP-20 pool.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least liquidity on the pool.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens to remove.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountAMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of tokenA that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of tokenB that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the underlying assets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenA received.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenB received.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "removeliquiditybnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquiditybnb",
        "aria-label": "removeliquiditybnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityBNB`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityBNB(
  address token,
  uint liquidity,
  uint amountTokenMin,
  uint amountBNBMin,
  address to,
  uint deadline
) external returns (uint amountToken, uint amountBNB);
`}</code></pre>
    <p>{`Removes liquidity from an BEP-20⇄WBNB pool and receive BNB.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least liquidity on the pool.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens to remove.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountTokenMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of token that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of BNB that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the underlying assets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountToken`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of token received.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB received.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "removeliquiditywithpermit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquiditywithpermit",
        "aria-label": "removeliquiditywithpermit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityWithPermit`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityWithPermit(
  address tokenA,
  address tokenB,
  uint liquidity,
  uint amountAMin,
  uint amountBMin,
  address to,
  uint deadline,
  bool approveMax, uint8 v, bytes32 r, bytes32 s
) external returns (uint amountA, uint amountB);
`}</code></pre>
    <p>{`Removes liquidity from an BEP-20⇄BEP-20 pool without pre-approval, thanks to `}<Link to='/docs/v1/smart-contracts/pair-bep-20/#permit' mdxType="Link">{`permit`}</Link>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tokenB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens to remove.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountAMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of tokenA that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of tokenB that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the underlying assets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`approveMax`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not the approval amount in the signature is for liquidity or `}<inlineCode parentName="td">{`uint(-1)`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`v`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The v component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`r`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bytes32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The r component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`s`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bytes32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The s component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountA`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenA received.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokenB received.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "removeliquiditybnbwithpermit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#removeliquiditybnbwithpermit",
        "aria-label": "removeliquiditybnbwithpermit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`removeLiquidityBNBWithPermit`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function removeLiquidityBNBWithPermit(
  address token,
  uint liquidity,
  uint amountTokenMin,
  uint amountBNBMin,
  address to,
  uint deadline,
  bool approveMax, uint8 v, bytes32 r, bytes32 s
) external returns (uint amountToken, uint amountBNB);
`}</code></pre>
    <p>{`Removes liquidity from an BEP-20⇄WETTH pool and receive BNB without pre-approval, thanks to `}<Link to='/docs/v1/smart-contracts/pair-bep-20/#permit' mdxType="Link">{`permit`}</Link>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`token`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pool token.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`liquidity`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of liquidity tokens to remove.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountTokenMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of token that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNBMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of BNB that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the underlying assets.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`approveMax`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not the approval amount in the signature is for liquidity or `}<inlineCode parentName="td">{`uint(-1)`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`v`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint8`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The v component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`r`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bytes32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The r component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`s`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bytes32`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The s component of the permit signature.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountToken`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of token received.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountBNB`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB received.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swapexacttokensfortokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexacttokensfortokens",
        "aria-label": "swapexacttokensfortokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactTokensForTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapExactTokensForTokens(
  uint amountIn,
  uint amountOutMin,
  address[] calldata path,
  address to,
  uint deadline
) external returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Swaps an exact amount of input tokens for as many output tokens as possible, along the route determined by the path. The first element of path is the input token, the last is the output token, and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least amountIn on the input token.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountIn`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of input tokens to send.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOutMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of output tokens that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the output tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swaptokensforexacttokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swaptokensforexacttokens",
        "aria-label": "swaptokensforexacttokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapTokensForExactTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapTokensForExactTokens(
  uint amountOut,
  uint amountInMax,
  address[] calldata path,
  address to,
  uint deadline
) external returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Receive an exact amount of output tokens for as few input tokens as possible, along the route determined by the path. The first element of path is the input token, the last is the output token, and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least amountInMax on the input token.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOut`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of output tokens to receive.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountInMax`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum amount of input tokens that can be required before the transaction reverts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the output tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swapexactbnbfortokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexactbnbfortokens",
        "aria-label": "swapexactbnbfortokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactBNBForTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapExactBNBForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline)
  external
  payable
  returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Swaps an exact amount of BNB for as many output tokens as possible, along the route determined by the path. The first element of path must be `}<a parentName="p" {...{
        "href": "#weth"
      }}>{`WBNB`}</a>{`, the last is the output token, and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`msg.value`}</inlineCode>{` (amountIn)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB to send.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOutMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of output tokens that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the output tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swaptokensforexactbnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swaptokensforexactbnb",
        "aria-label": "swaptokensforexactbnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapTokensForExactBNB`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapTokensForExactBNB(uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline)
  external
  returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Receive an exact amount of BNB for as few input tokens as possible, along the route determined by the path. The first element of path is the input token, the last must be `}<a parentName="p" {...{
        "href": "#weth"
      }}>{`WBNB`}</a>{`, and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`msg.sender`}</inlineCode>{` should have already given the router an allowance of at least amountInMax on the input token.`}</li>
      <li parentName="ul">{`If the to address is a smart contract, it must have the ability to receive BNB.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOut`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of BNB to receive.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountInMax`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum amount of input tokens that can be required before the transaction reverts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of BNB.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swapexacttokensforbnb",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapexacttokensforbnb",
        "aria-label": "swapexacttokensforbnb permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapExactTokensForBNB`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapExactTokensForBNB(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline)
  external
  returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Swaps an exact amount of tokens for as much BNB as possible, along the route determined by the path. The first element of path is the input token, the last must be `}<a parentName="p" {...{
        "href": "#weth"
      }}>{`WBNB`}</a>{`, and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <ul>
      <li parentName="ul">{`If the to address is a smart contract, it must have the ability to receive BNB.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountIn`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of input tokens to send.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOutMin`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The minimum amount of output tokens that must be received for the transaction not to revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the BNB.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "swapbnbforexacttokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swapbnbforexacttokens",
        "aria-label": "swapbnbforexacttokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`swapBNBForExactTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function swapBNBForExactTokens(uint amountOut, address[] calldata path, address to, uint deadline)
  external
  payable
  returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Receive an exact amount of tokens for as little BNB as possible, along the route determined by the path. The first element of path must be `}<a parentName="p" {...{
        "href": "#weth"
      }}>{`WBNB`}</a>{`, the last is the output token and any intermediate elements represent intermediate pairs to trade through (if, for example, a direct pair does not exist).`}</p>
    <ul>
      <li parentName="ul">{`Leftover BNB, if any, is returned to `}<inlineCode parentName="li">{`msg.sender`}</inlineCode>{`.`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amountOut`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The amount of tokens to receive.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`msg.value`}</inlineCode>{` (amountInMax)`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The maximum amount of BNB that can be required before the transaction reverts.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`path`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address[] calldata`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An array of token addresses. `}<inlineCode parentName="td">{`path.length`}</inlineCode>{` must be >= 2. Pools for each consecutive pair of addresses must exist and have liquidity.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`to`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Recipient of the output tokens.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`deadline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Unix timestamp after which the transaction will revert.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`amounts`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`uint[] memory`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The input token amount and all subsequent output token amounts.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "quote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quote",
        "aria-label": "quote permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`quote`}</h2>
    <p>{`See `}<Link to='/docs/v1/smart-contracts/library#quote' mdxType="Link">{`quote`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountout",
        "aria-label": "getamountout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountOut`}</h2>
    <p>{`See `}<Link to='/docs/v1/smart-contracts/library#getamountout' mdxType="Link">{`getAmountOut`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountin",
        "aria-label": "getamountin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountIn`}</h2>
    <p><strong parentName="p">{`This function contains a low severity bug, do not use.`}</strong></p>
    <h2 {...{
      "id": "getamountsout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsout",
        "aria-label": "getamountsout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsOut`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`See `}<Link to='/docs/v1/smart-contracts/library#getamountsout' mdxType="Link">{`getAmountsOut`}</Link>{`.`}</p>
    <h2 {...{
      "id": "getamountsin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsin",
        "aria-label": "getamountsin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsIn`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsIn(uint amountOut, address[] memory path) public view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`See `}<Link to='/docs/v1/smart-contracts/library#getamountsin' mdxType="Link">{`getAmountsIn`}</Link>{`.`}</p>
    <h1 {...{
      "id": "interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#interface",
        "aria-label": "interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interface`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`import '@beadswap/v1-periphery/contracts/interfaces/IBeadswapV1Router01.sol';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity >=0.6.2;

interface IBeadswapV1Router01 {
  function factory() external pure returns (address);
  function WBNB() external pure returns (address);

  function addLiquidity(
      address tokenA,
      address tokenB,
      uint amountADesired,
      uint amountBDesired,
      uint amountAMin,
      uint amountBMin,
      address to,
      uint deadline
  ) external returns (uint amountA, uint amountB, uint liquidity);
  function addLiquidityBNB(
      address token,
      uint amountTokenDesired,
      uint amountTokenMin,
      uint amountBNBMin,
      address to,
      uint deadline
  ) external payable returns (uint amountToken, uint amountBNB, uint liquidity);
  function removeLiquidity(
      address tokenA,
      address tokenB,
      uint liquidity,
      uint amountAMin,
      uint amountBMin,
      address to,
      uint deadline
  ) external returns (uint amountA, uint amountB);
  function removeLiquidityBNB(
      address token,
      uint liquidity,
      uint amountTokenMin,
      uint amountBNBMin,
      address to,
      uint deadline
  ) external returns (uint amountToken, uint amountBNB);
  function removeLiquidityWithPermit(
      address tokenA,
      address tokenB,
      uint liquidity,
      uint amountAMin,
      uint amountBMin,
      address to,
      uint deadline,
      bool approveMax, uint8 v, bytes32 r, bytes32 s
  ) external returns (uint amountA, uint amountB);
  function removeLiquidityBNBWithPermit(
      address token,
      uint liquidity,
      uint amountTokenMin,
      uint amountBNBMin,
      address to,
      uint deadline,
      bool approveMax, uint8 v, bytes32 r, bytes32 s
  ) external returns (uint amountToken, uint amountBNB);
  function swapExactTokensForTokens(
      uint amountIn,
      uint amountOutMin,
      address[] calldata path,
      address to,
      uint deadline
  ) external returns (uint[] memory amounts);
  function swapTokensForExactTokens(
      uint amountOut,
      uint amountInMax,
      address[] calldata path,
      address to,
      uint deadline
  ) external returns (uint[] memory amounts);
  function swapExactBNBForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline)
      external
      payable
      returns (uint[] memory amounts);
  function swapTokensForExactBNB(uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline)
      external
      returns (uint[] memory amounts);
  function swapExactTokensForBNB(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline)
      external
      returns (uint[] memory amounts);
  function swapBNBForExactTokens(uint amountOut, address[] calldata path, address to, uint deadline)
      external
      payable
      returns (uint[] memory amounts);

  function quote(uint amountA, uint reserveA, uint reserveB) external pure returns (uint amountB);
  function getAmountOut(uint amountIn, uint reserveIn, uint reserveOut) external pure returns (uint amountOut);
  function getAmountIn(uint amountOut, uint reserveIn, uint reserveOut) external pure returns (uint amountIn);
  function getAmountsOut(uint amountIn, address[] calldata path) external view returns (uint[] memory amounts);
  function getAmountsIn(uint amountOut, address[] calldata path) external view returns (uint[] memory amounts);
}
`}</code></pre>
    <h1 {...{
      "id": "abi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#abi",
        "aria-label": "abi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import IBeadswapV1Router01 from '@beadswap/v1-periphery/build/IBeadswapV1Router01.json'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      